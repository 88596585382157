import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './Components/Sass/main.scss'
import Home from './Components/Pages/Home';
import PrivacyPolicy from './Components/Pages/PrivacyPolicy'
import Rastrol10 from './Components/Pages/Rastrol10';
import Rastrol20 from './Components/Pages/Rastrol20';
import Rastrol40 from './Components/Pages/Rastrol40';
import RastrolGold from './Components/Pages/RastrolGold';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products/rastrol-10" element={<Rastrol10 />} />
          <Route path="/products/rastrol-20" element={<Rastrol20 />} />
          <Route path="/products/rastrol-40" element={<Rastrol40 />} />
          <Route path="/products/rastrol-gold" element={<RastrolGold />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
